
import { Component, Vue, Prop } from 'vue-property-decorator';
import MetaInfo from 'vue-meta';

@Component({
  components: {},
  metaInfo(this: JnArchivio): MetaInfo {
    return { title: 'JuranNews - Archivio' };
  },
})
export default class JnArchivio extends Vue {}
